import React from "react"
import { StaticImage, getImage} from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import TrustedBy from "../components/hireVa/trusted"
import SaveTime from "../components/hireVa/savetime"
import GoodChoice from '../components/hireVa/goodchoice'
import PostingRole from '../components/hireVa/postingrole'
import Assistant from '../components/hireVa/assistant'
import Booking from '../components/hireVa/booking'

import Testimonials from "../components/testimonials"
import Faqs from "../components/faqs.js"
import GetInTouch from  '../components/hireVa/getintouch'
import { useStaticQuery, graphql, Link } from "gatsby"



const CreativeService = ({ data }) => { 

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };

      const banner_title  = ['Fully Managed', 3000, 'Fully Trained ', 2000];
      const banner_button = 'Hire Vetted Virtual Assistant';

return (

    <Layout>
<Seo title="Find the best freelance Virtual Assistants as per Demand  " />
<Banner title = {banner_title} sticky_first = "Hire" sticky_second  = "Creative Designer" button = {banner_button} />
<section className = "inro" >
<div className = "container">
  <p>All of our creative va are trained in exclusive in house boot camps</p>
</div>
</section>
{/* 
        <div className = "vr_banner inner">
        <StaticImage
                            src="../images/ceative-banner.png"
                            quality = "90"
                            layout = "fullWidth"
                            alt="A Gatsby astronaut"
                            placeholder="tracedSVG"
                            />

        <div className = "details container">
        <div className = "details_wrap">

            <h1>Let Creative Designer Handle your Cumbersome Work</h1>
            <p>
            Delegate all your design work to Creative VA. Our global designers are flexible and trained for all kinds of Complex Designs.  
            </p>
            <Link to = "https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discoverycall">Hire Creative VA </Link>
        </div>
        </div>


        </div>
*/}

<section className = "trained serv_det">

    <div className="container">

     

        <ul>

        <li>
        <StaticImage
            src="../images/ps.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Adobe Photoshop</h4>

        </li>


        
        <li>
        <StaticImage
            src="../images/ai.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Adobe Illustrator</h4>

        </li>



        <li>
        <StaticImage
            src="../images/xd.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Adobe XD</h4>

        </li>



        <li>
        <StaticImage
            src="../images/id.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Adobe Indesign</h4>

        </li>



        <li>
        <StaticImage
            src="../images/pr.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Adobe Premiere pro</h4>

        </li>
        <li>
        <StaticImage
            src="../images/after_effects.png"
            quality = "100"
          width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Adobe After Effects</h4>

        </li>
    </ul>

    </div>


</section>


<TrustedBy/>


<SaveTime/>


<GoodChoice/>


{/* 
<section className = "trained serv_det">

    <div className="container">

      <h1>ALL OF OUR CREATIVE VA ARE TRAINED IN EXCLUSIVE IN HOUSE BOOT CAMPS </h1>

        <ul>

        <li>
        <StaticImage
            src="../images/ps.png"
            quality = "100"
          
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Adobe Photoshop</h4>

        </li>


        
        <li>
        <StaticImage
            src="../images/ai.png"
            quality = "100"
          
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Adobe Illustrator</h4>

        </li>



        <li>
        <StaticImage
            src="../images/xd.png"
            quality = "100"
          
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Adobe XD</h4>

        </li>



        <li>
        <StaticImage
            src="../images/id.png"
            quality = "100"
          
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Adobe Indesign</h4>

        </li>



        <li>
        <StaticImage
            src="../images/pr.png"
            quality = "100"
          
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Adobe Premiere pro</h4>

        </li>
    </ul>

    </div>


</section>
*/}

<section className = "trained serv_det">

    <div className="container">

      <h1>WHAT CAN A CREATIVE DESIGNER VIRTUAL ASSISTANT DO?</h1>
      <p>A creative designer creates useful, meaningful, and functional visuals using various means of technology.
           The work depends largely on your or 
          company's needs, but generally virtual creative designer responsibilities may include: developing 
          visual assets to support a marketing campaign, designing a graphic overlay for social media posts, 
          formalizing the layout for a print ad, and retouching photos for digital signage.</p>


<div className = "row">

        <ul>

        <li>
        <StaticImage
            src="../images/Branding.png"
            quality = "100"
          width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Branding</h4>

        </li>


        
        <li>
        <StaticImage
            src="../images/Brochures-and-Posters-icon.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Brochures & Posters</h4>

        </li>



        <li>
        <StaticImage
            src="../images/social-media-patch.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Social media content</h4>

        </li>



        <li>
        <StaticImage
            src="../images/designing-pitch.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Designing & pitch</h4>

        </li>



        <li>
        <StaticImage
            src="../images/designing-websites.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Designing websites</h4>

        </li>
        <li>
        <StaticImage
            src="../images/emails-campaigns.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Emails Campaigns</h4>

        </li>

        <li>
        <StaticImage
            src="../images/Image-video.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Image & video editing</h4>

        </li>
        <li>
        <StaticImage
            src="../images/Infographics.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Info Graphics</h4>

        </li>
    </ul>
    </div>


    </div>


</section>

<section className = "service_package">

<h1>CHOOSE YOUR PLAN</h1>
<div className = "container">

    

<div class="naccs">
  <div class="grid">
   <div class="gc gc--1-of-3">
    <div class="menu">
     <div class="active"><span class="light"></span><span>Part Time VA</span></div>
     <div><span class="light"></span><span>Dedicated VA</span></div>
   
    </div>
   </div>
   <div class="gc gc--2-of-3">
    <ul class="nacc">
     <li class="active">
      <div className = "inner_data">

        <div className = "list">
          <h2>Part Time VA</h2>
          <span><i class="fa fa-check"></i> Picture Editing</span>
          <span><i class="fa fa-check"></i> Logo & Banner</span>
          <span><i class="fa fa-check"></i> Website Banner Ad   </span>
          <span><i class="fa fa-check"></i> Social Media timeline Cover</span>
          <span><i class="fa fa-check"></i> Ads banner for Social Media</span>
          <span><i class="fa fa-check"></i> Profile Pictures editing</span>
          <span><i class="fa fa-times"></i> Print advertisements</span>
          <span><i class="fa fa-times"></i> Brochures/Leaflets/Postcards & Letterhead</span>
          <span><i class="fa fa-times"></i> Book cover</span>
          <span><i class="fa fa-check"></i> Email Signature</span>
          <span><i class="fa fa-times"></i> Photo Animation creation Gif'S</span>
          <span><i class="fa fa-times"></i> Any other printable media designing work</span>
        </div>

        <div className = "start_now">
          <h2>$20</h2>
          <span>Per Hour</span>
          <Link to = "https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discovery-call-for-virtual-assistant">start now</Link>
        </div>
       
     
      </div>
     </li>
     <li>
     <div className = "inner_data">

<div className = "list">
<h2>Dedicated VA</h2>
  <span><i class="fa fa-check"></i> Picture Editing</span>
  <span><i class="fa fa-check"></i> Logo & Banner</span>
  <span><i class="fa fa-check"></i> Website Banner Ad  </span>
  <span><i class="fa fa-check"></i> Social Media timeline Cover</span>
  <span><i class="fa fa-check"></i> Ads banner for Social Media</span>
  <span><i class="fa fa-check"></i> Profile Pictures editing</span>
  <span><i class="fa fa-check"></i> Print advertisements</span>
  <span><i class="fa fa-check"></i> Brochures/Leaflets/Postcards & Letterhead</span>
  <span><i class="fa fa-check"></i> Book cover</span>
  <span><i class="fa fa-check"></i> Email Signature</span>
  <span><i class="fa fa-check"></i> Photo Animation creation Gif'S</span>
  <span><i class="fa fa-check"></i> Any other printable media designing work</span>
</div>

<div className = "start_now">
  <h2>$17</h2>
  <span>Per Hour</span>
  <Link to = "https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discoverycall">start now</Link>
</div>


</div>


     </li>
   
    </ul>
   </div>
  </div>
 </div>

</div>


</section>


<PostingRole/>


<Assistant/>


<Booking/>

{/* 
<section className = "service_testi">
    <div className="container">
    <h1>What clients say!</h1>
<Slider {...settings}>
      <div>
        <p>I am extremely happy and satisfied with the designs. My overall experience with Estore Company’s VA is awesome. They are amazing people – professional, helpful, extremely knowledgeable, experienced and dedicated. 
</p>
        <h6>- Susan P Jackson </h6>
      </div>
      <div>
      <p>Super-Creative and a visionary company! Great experience, extremely easy to work with and always get you a great finished design. Their Creative VA is skilled in producing killer designs that actually pave the way for a cool and unique user experience. 

</p>
        <h6>- Christopher Wright  </h6>
      </div>
      <div>
      <p>The finished designs are so much better than I could have imagined! I’m happy with the service, and look forward to continuing our relationship with Estore Company. I’m planning to put them on a retainer for everyday design tasks.  


</p>
        <h6>- Daniel Baker</h6>
      </div>
      <div>
      <p>I recommend Estore Company’s VA to my friends for a reason - creativity and enthusiasm. They know the art of bringing ideas to life and in the most pleasant way. My project was a smooth sailing, easy breezy process. 



</p>
        <h6>- Jennifer K. Miller </h6>
      </div>

    </Slider>
    </div>
    </section>
*/}

<Testimonials/>

    <Faqs/>
<GetInTouch/>

 </Layout>

)


}

export default CreativeService;